@import "../../../styles/tools";
@import "../../../styles/mediaqueries";

.intro {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: var(--space-1);
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;

  &.large {
    gap: var(--space-2);
  }
}

.label {
  color: var(--black);
  font-size: pxToRem(10);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.title {
  margin: 0 auto;
  font-weight: var(--font-weight-bold);
  color: var(--black);
  max-width: pxToRem(960);

  &.large {
    font-size: pxToRem(32);

    @include desktop {
      font-size: pxToRem(40);
    }
  }

  &.bold {
    font-weight: var(--font-weight-bold);
  }
}

.description {
  color: var(--black);
  max-width: pxToRem(720);

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  * + * {
    margin-top: var(--space-2);
  }

  &.small {
    font-size: pxToRem(16);
  }

  &.large {
    font-size: pxToRem(20);

    @include desktop {
      font-size: pxToRem(24);
    }
  }

  &.bold {
    font-weight: var(--font-weight-medium);
  }

  &.faded {
    p {
      color: var(--black-transparent-90);
    }
  }
}

.buttons {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-2);
  margin-top: var(--space-1);

  &:empty {
    display: none !important;
  }
}

/////////////////////////////////////
/// Width
/////////////////////////////////////
.contained {
}

.featured {
  .title,
  .description {
    max-width: pxToRem(960);
  }
}

.full {
  .title,
  .description {
    max-width: 100%;
  }
}

/////////////////////////////////////
/// Space
/////////////////////////////////////
.horizontalSpace {
  @include desktop {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
}

.noHorizontalSpace {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.verticalSpace {
  @include desktop {
    padding-left: var(--space-8);
    padding-right: var(--space-8);
  }
}

.noVerticalSpace {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/////////////////////////////////////
/// Alignment
/////////////////////////////////////
.alignCenter {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.alignLeft,
.alignRight {
  .title {
    margin-left: initial;
    margin-right: initial;
  }
}

.alignLeft {
  text-align: left;
  align-items: flex-start;

  .buttons {
    justify-content: flex-start;
  }
}

.alignRight {
  @include laptop {
    text-align: right;
    align-items: flex-end;

    .buttons {
      justify-content: flex-end;
    }
  }
}

/////////////////////////////////////
/// Default
/////////////////////////////////////
.default {
}

/////////////////////////////////////
/// Typography
/////////////////////////////////////
.markdown {
  min-height: 1rem;

  p {
    &:first-of-type {
      margin-top: 0;
    }
  }

  ul,
  ol {
    padding: 0;
    list-style: none;
    counter-reset: item;

    li {
      position: relative;
      margin-top: pxToRem(12);

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin: 0;
      }
    }
  }

  ul {
    li {
      padding-left: var(--space-4);
      &::before {
        content: "";
        position: absolute;
        height: pxToRem(4);
        width: pxToRem(4);
        border-radius: 45%;
        left: var(--space-2);
        top: pxToRem(12);
        background-color: var(--black-transparent-75);
      }
    }
  }

  ol {
    counter-reset: item 0;

    @for $i from 2 through 20 {
      &[start="#{$i}"] {
        counter-reset: item #{$i - 1};
      }
    }

    li {
      padding-left: var(--space-3);
      &::before {
        content: counter(item) ".";
        counter-increment: item;
        position: absolute;
        left: 0;
        font-variant-numeric: tabular-nums;
        color: var(--black-transparent-75);
      }

      p {
        position: relative;
        top: -2px;
      }
    }
  }

  a:not([class*="Button"]) {
    color: var(--tobii-link-color);
    word-break: break-all;
  }

  &:empty {
    display: none;
    height: 0;
  }
}

.inverted .markdown {
  ul {
    li {
      &::before {
        background-color: var(--white-transparent-75);
      }
    }
  }

  ol {
    li {
      &::before {
        color: var(--white-transparent-75);
      }
    }
  }

  a:not([class*="Button"]) {
    color: var(--white);
    text-decoration: underline;
    text-decoration-color: var(--white);

    &:hover {
      color: var(--white);
      text-decoration-color: none;
    }
  }
}

/////////////////////////////////////
/// Inverted
/////////////////////////////////////
.inverted {
  .title {
    color: var(--white);
  }

  .description {
    color: var(--white);

    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      color: var(--white);
    }

    &.faded p {
      color: var(--white-transparent-90);
    }
  }
}
