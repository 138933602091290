@import "../../styles/mediaqueries";
@import "../../styles/tools";
@import "../../styles/mixins";

.section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--space-15) 0;
}
